import { Car, Equipment, Rate } from "@basset-la/components-cars/dist/models"
import styles from "./Details.styles"
import ReactGA from "react-ga"
import qs from "qs"
import PriceBreakdownWeb from "@basset-la/components-cars/dist/components/PriceBreakdownWeb"
import { useHistory, useLocation, useRouteMatch } from "react-router-dom"
import { useEffect, useState } from "react"
import { fetchDetails } from "../../api/cars"
import { useTranslation } from "react-i18next"
import { I18N_NS } from "../../utils/constants"
import { useConfig } from "@basset-la/components-commons"
import ProductLoader from "@basset-la/components-commons/dist/components/ProductLoader"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Card from "./Card"
import CarFeatures from "@basset-la/components-cars/dist/components/CarFeatures"
import CarPlanCoverage from "@basset-la/components-cars/dist/components/CarPlanCoverage"
import CarPickupDropoff from "@basset-la/components-cars/dist/components/CarPickupDropoff"
import Button from "@basset-la/components-commons/dist/components/Button"
import AdditionalPrice from "@basset-la/components-cars/dist/components/AdditionalPrice"
import CarEquipment from "@basset-la/components-cars/dist/components/CarEquipment"
import { EquipmentAction } from "@basset-la/components-cars/dist/components/CarEquipment/CarEquipment"
import Wrapper from "../Wrapper"
import CarRateMap from "@basset-la/components-cars/dist/components/CarRateMap"
import moment from "moment"
import ErrorMessage from "../ErrorMessage/ErrorMessage"
import Sticky from "react-stickynode"
import CancellationPolicyText from "@basset-la/components-cars/dist/components/CancellationPolicyText"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import Message from "@basset-la/components-commons/dist/components/Message"
import { parseSearchboxParams } from "../../utils/helpers/mapper"

interface RouteMatchProps {
  carRateId: string
}

const Details: React.FC = () => {
  const config = useConfig()
  const { t } = useTranslation(I18N_NS)
  const routeMatch = useRouteMatch<RouteMatchProps>()
  const { search } = useLocation()
  const history = useHistory()

  const isMobile = useMediaQuery("(max-width: 1024px)")

  const [isLoading, setIsLoading] = useState(false)
  const [car, setCar] = useState<Car | null>(null)
  const [addedEquipments, setAddedEquipments] = useState<Equipment[]>([])
  const [openPickupMap, setOpenPickupMap] = useState(false)
  const [openDropoffMap, setOpenDropoffMap] = useState(false)
  const [reservationExpirationHours, setReservationExpirationHours] = useState(0)
  const [openTerms, setOpenTerms] = useState(false)

  useEffect(() => {
    document.title = t("Details.documentTitle")
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [t])

  useEffect(() => {
    const fetchCar = async () => {
      try {
        setIsLoading(true)
        const response = await fetchDetails(routeMatch.params.carRateId, config.config)
        // Fix: filter equipments with empty type and price 0
        const car = response.car
        car.equipments.chargeables = car.equipments.chargeables.filter((e) => (e.type as string) !== "" && e.amount > 0)
        setCar(car)
        setReservationExpirationHours(response.reservation_expiration_hours || 0)
      } catch (e) {
        console.log(e)
      } finally {
        setIsLoading(false)
      }
    }

    if (!car) {
      fetchCar()
    }
  }, [car, config.config, routeMatch.params.carRateId])

  const handleSearchAgain = () => {
    history.push({
      pathname: "/cars/results",
      search: search,
    })
  }

  const handleBuy = () => {
    const params = parseSearchboxParams(search)

    const checkoutPath =
      config!.config.checkout_url ||
      "/checkout/{product}/{id}?plan={plan}&driver_age={driver_age}&equipments={equipments}"

    const ids = addedEquipments.map((e) => e.type).join(",")

    let replacedPath = checkoutPath
      .replace("{id}", routeMatch.params.carRateId)
      .replace("{product}", "cars")
      .replace("{tracking_id}", car?.tracking_id ?? "")
      .replace("{plan}", car!.plan)
      .replace("{driver_age}", `${params.driverAge}`)
      .replace("{equipments}", ids)

    const { _ga } = qs.parse(window.location.search.substr(1))

    if (_ga) {
      replacedPath = replacedPath.replace("{_ga}", _ga as string)
    } else {
      const ga = ReactGA?.ga()

      if (ga) {
        ga(function (tracker) {
          let clientId = tracker.get("clientId")
          replacedPath = replacedPath.replace("{_ga}", clientId)
        })
      }
    }

    window.location.href = replacedPath
  }

  const handleChangePlanClick = () => {
    setCar({
      ...car!,
      plan: otherPlanType,
    })
  }

  const handleEquipmentClick = (e: Equipment, a: EquipmentAction) => {
    let copy = [...addedEquipments]

    switch (a) {
      case "add":
      case "request":
        copy.push(e)
        break
      case "remove":
        copy = copy.filter((eq) => eq.type !== e.type)
        break
    }

    setAddedEquipments(copy)
  }

  const handleTogglePickupMap = () => {
    setOpenPickupMap(!openPickupMap)
  }

  const handleToggleDropoffMap = () => {
    setOpenDropoffMap(!openDropoffMap)
  }

  const handleOpenTerms = () => {
    setOpenTerms(true)
  }

  const handleCloseTerms = () => {
    setOpenTerms(false)
  }

  const otherPlanType = car?.plan === "BASIC" ? "PREMIUM" : "BASIC"
  const otherPlan = car ? (otherPlanType === "BASIC" ? car.coverages.basic_plan : car.coverages.premium_plan) : null
  const daysDiff = car ? moment(car.dropoff.date).diff(moment(car.pickup.date), "days") : 0

  return (
    <Wrapper>
      <div className={styles.root(isMobile)}>
        {isLoading && <ProductLoader is_mobile={isMobile} variant="WEB" product_variant="CARS_RESULT" />}
        {!isLoading && !car && (
          <ErrorMessage
            title={t("Details.notFound.title")}
            message={t("Details.notFound.message")}
            buttonText={t("Details.notFound.button")}
            onButtonClick={handleSearchAgain}
          />
        )}
        {!isLoading && car && (
          <>
            <Dialog open={openTerms} onClose={handleCloseTerms}>
              <DialogTitle>{t("Details.terms")}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <span
                    className={styles.longText}
                    dangerouslySetInnerHTML={{ __html: car.terms_and_conditions || "" }}
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions />
            </Dialog>

            <div id="contentContainer" className={styles.content}>
              <Card fullHeight>
                <CarFeatures
                  cluster={car}
                  selectedRate={car}
                  isMobile={isMobile}
                  variant="wide"
                  isDetail={true}
                  iconsSize={32}
                />
              </Card>

              <div className={styles.contentRow}>
                <div className={styles.contentRowItem}>
                  <Card
                    fullHeight
                    title={t("Details.plan", { plan: `${car.rate.code} ${car.rate.name ? `(${car.rate.name})` : ""}` })}
                  >
                    <CarPlanCoverage
                      coverage={car.plan === "BASIC" ? car.coverages.basic_plan : car.coverages.premium_plan}
                      includedEquipments={car.equipments.included}
                    />
                  </Card>
                </div>
                <div className={styles.contentRowItem}>
                  <Card fullHeight title={t(`Details.pickupDropoff`)}>
                    <CarPickupDropoff
                      type="pickup"
                      showTitle
                      showDate={false}
                      showSameLocationAddress={false}
                      data={car.pickup}
                      embeddedMap={
                        openPickupMap ? (
                          <CarRateMap
                            variant={"pickup"}
                            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}
                            rate={car as Rate}
                          />
                        ) : undefined
                      }
                      onOpenMap={handleTogglePickupMap}
                    />
                    <CarPickupDropoff
                      type="dropoff"
                      showTitle
                      showDate={false}
                      showSameLocationAddress={car.pickup.location.address === car.dropoff.location.address}
                      data={car.dropoff}
                      embeddedMap={
                        openDropoffMap ? (
                          <CarRateMap
                            variant={"dropoff"}
                            apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}
                            rate={car as Rate}
                          />
                        ) : undefined
                      }
                      onOpenMap={handleToggleDropoffMap}
                    />
                  </Card>
                </div>
              </div>

              {car.coverages.premium_plan.coverages.length > 0 && (
                <Card
                  fullHeight
                  title={t(`Details.changePlan.${otherPlanType}`)}
                  imageUrl="https://statics.basset.la/cars/car-details.png"
                >
                  <CarPlanCoverage coverage={otherPlan!} includedEquipments={car.equipments.included} />
                  {otherPlanType === "PREMIUM" && (
                    <AdditionalPrice currency={car.fare.currency} total={otherPlan!.total} days={daysDiff} />
                  )}
                  <Button variant="outlined" onClick={handleChangePlanClick}>
                    {t("Details.changePlanButton")}
                  </Button>
                </Card>
              )}

              {car.equipments.chargeables.length > 0 && (
                <Card fullHeight title={t("Details.equipments")}>
                  <Message open variant="fixed" action="warning" message={t("Details.warning")} />

                  <div className={styles.contentRowEquipments}>
                    {car.equipments.chargeables.map((equipment, i) => {
                      const found = addedEquipments.find((e) => e.type === equipment.type)
                      return (
                        <Card width="240px">
                          <CarEquipment
                            key={`equipment_${i}`}
                            days={daysDiff}
                            equipment={equipment}
                            action={!found ? "add" : "remove"}
                            onClick={handleEquipmentClick}
                          />
                        </Card>
                      )
                    })}
                  </div>
                </Card>
              )}

              <Card fullHeight title={t("Details.policies")}>
                <CancellationPolicyText
                  className={styles.longText}
                  reservationExpirationHours={reservationExpirationHours}
                  carPickupDate={car.pickup.date}
                />
              </Card>

              {isMobile && (
                <Button variant="text" onClick={handleOpenTerms}>
                  {t("Details.openTerms")}
                </Button>
              )}
            </div>

            <div className={styles.priceboxContainer(isMobile)}>
              <Sticky enabled={!isMobile} innerZ={200} top={0} bottomBoundary="#contentContainer">
                <div className={styles.pricebox(isMobile)}>
                  <PriceBreakdownWeb isMobile={isMobile} rate={car!} equipments={addedEquipments} />
                  {!isMobile && (
                    <Button className={styles.button(true)} variant="text" onClick={handleOpenTerms}>
                      {t("Details.openTerms")}
                    </Button>
                  )}
                  <Button className={styles.button(isMobile)} variant="contained" onClick={handleBuy}>
                    {t("Details.buy")}
                  </Button>
                </div>
              </Sticky>
            </div>
          </>
        )}
      </div>
    </Wrapper>
  )
}

export default Details
